import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax} from "../../../styles/device";

export const NotfoundWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 808px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin: 0 auto 30px;
  background-color: ${Colors.softGreyColor};

  @media ${deviceMax.tablet} {
    background-color: transparent;
  }
`;

export const NotfoundContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 465px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 20px;

  @media ${deviceMax.tablet} {
    padding: 70px 0 25px;
  }
`;

export const TitleComp = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media ${deviceMax.tablet} {
    justify-content: center;
    margin-bottom: 0;
  }
`;

export const NotfoundTitle = styled.h1`
  position: relative;
  color: ${Colors.black};
  margin-bottom: 0;
  margin-top: 0;
  font-size: 18px;
  font-weight: 800;
  line-height: 55px;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;

`;

export const NotfoundDetail = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  padding: 47px 30px 92px;
  border-radius: 6px;
  margin-bottom: 20px;

  > div {
    :is(:nth-child(2)) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0;

      > h2 {
        font-weight: 800;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0;
        color: ${Colors.black};
        margin-top: 0;
        margin-bottom: 40px;
        width: 100%;

        @media ${deviceMax.tablet} {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }

      @media ${deviceMax.tablet} {
        background-color: transparent;
        margin-bottom: 10px;
      }
    }
  }

  @media ${deviceMax.tablet} {
    background-color: transparent;
    padding: 0;
  }
`;

export const NotfoundFormTriangle = styled.span`
  position: absolute;
  top: -30px;
  left: 20px;
  width: 0;
  height: 0;
  border-bottom: 30px solid ${Colors.white};
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;

  @media ${deviceMax.tablet} {
    display: none;
  }
`;


export const NotfoundIcon = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  > img {
    width: 100%;
    
    @media ${deviceMax.tablet} {
      max-width: 150px;
    }
  }
`;

export const ReturnHomePageButton = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  > div {
    display: block;
    padding: 0;
    margin-bottom: 0;

    @media ${deviceMax.tablet} {

      > a {
        background-color: ${Colors.black};

      }
    }
  }
`;
