import React from "react";
import Colors from "../../constants/Colors";
import ButtonComp from "../buttons/buttonComp/buttonComp";
import {NavigationURLs} from "../../functions/NavigationFunctions";

const ContinueButton = () => {
    return (
        <ButtonComp backgroundColor={Colors.brandColor} visible={false} fullWidth={true} textColor={true}
                    uppercase={true} url={NavigationURLs.landing}>
            {'Ana sayfaya dön'}
        </ButtonComp>
    );
};

export default ContinueButton;
