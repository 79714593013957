import React from "react";
import * as Styled from "./NotFoundCompStyled";
import TextArea from "../textArea/TextArea";
import Image from "next/image";
import ReturnHomePage from "../generalButtons/ReturnHomePage";

const NotFoundComp = () => {
    return (
        <React.Fragment>
            <Styled.NotfoundWrapper>
                <Styled.NotfoundContent>
                    <Styled.TitleComp>
                        <Styled.NotfoundTitle>
                            Sayfa Bulunamadı
                        </Styled.NotfoundTitle>
                    </Styled.TitleComp>
                    <Styled.NotfoundDetail>
                        <Styled.NotfoundFormTriangle/>
                        <TextArea>
                            <h2>
                                404 <br/>
                                Aradığınız sayfa bulunamadı.
                            </h2>
                            <Styled.NotfoundIcon>
                                <Image src={'/next/assets/images/404/404.svg'} width={239} height={217} alt={'sayfa bulunamadı'}/>
                            </Styled.NotfoundIcon>
                        </TextArea>
                    </Styled.NotfoundDetail>
                    <Styled.ReturnHomePageButton>
                        <ReturnHomePage/>
                    </Styled.ReturnHomePageButton>
                </Styled.NotfoundContent>
            </Styled.NotfoundWrapper>
        </React.Fragment>
    )
}

export default NotFoundComp;
