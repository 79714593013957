import React from "react";
import type {NextPage} from "next";
import HtmlHead from "../src/components/headerAndFooter/HtmlHead";
import WaveLayout from "../src/layouts/WaweLayout";
import NotFoundComp from "../src/components/notFound/NotFoundComp";

const NotFound: NextPage = () => {
    return (
        <React.Fragment>

            <HtmlHead
                pageTitle={'404 - Pembe Panjur'}
            />
            <WaveLayout bodyBackgroundColor={true} backgroundColor={'unset'} loginButtonText={true}
                        hamburgerMenu={false} loginButtonMenu={true} registerButton={false}>
                <NotFoundComp/>
            </WaveLayout>

        </React.Fragment>
    )
}

export default NotFound;
